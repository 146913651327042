/**
 * Write-only the password as cookie
 */
import React, { useState } from 'react';
import Button from '../../../components/Button/button.js'
import { setSessionPassword } from '../utils/utils.js';

const styles = {
  wrapper: {
    height: '100%',
    minHeight: '100vh',
    backgroundColor: '#212121',
    backgroundColor: 'var(--bg)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    backgroundColor: '#212121',
    backgroundColor: 'var(--bg)',
    color: '#ff0',
    color: 'var(--primary)',
    border: '1px solid #ff0',
    width: '100%',
    marginRight: '8px',
    padding: '.5rem .75rem',
    height: '23px'
  },
  button: {
    cursor: 'pointer',
    backgroundColor: '#212121',
    backgroundColor: 'var(--bg)',
    color: '#ff0',
    color: 'var(--primary)',
    border: '1px solid #ff0',
    padding: '.25rem 1.25rem'
  }
};

const PasswordProtect = () => {
  const [password, setPassword] = useState('');
  const [isButtonHovered, buttonHover] = useState(false);
  const [isThemeHovered, themeHover] = useState(false);
  const [isSiteHovered, siteHover] = useState(false);

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(true); // eslint-disable-line
  };

  return (
    <div className="password" style={styles.wrapper}>
      <h1 style={{ color: '#fff' }}>Welcome</h1>
      <h4 style={{ color: '#fff' }}>Enter Password</h4>

      <form onSubmit={onSubmit} style={{ display: 'flex'}}>
        <input
          name="password"
          type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          style={styles.input}
        />

        <button
          type="submit"
          style={{
            ...styles.button
          }}
          onMouseEnter={() => buttonHover(true)}
          onMouseLeave={() => buttonHover(false)}
        >
          Enter
        </button>
      </form>
      <script>
      {`
        window['_fs_debug'] = false;
        window['_fs_host'] = 'fullstory.com';
        window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
        window['_fs_org'] = '15F5C2';
        window['_fs_namespace'] = 'FS';
        (function(m,n,e,t,l,o,g,y){
            if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
            g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
            o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
            y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
            g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
            g.anonymize=function(){g.identify(!!0)};
            g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
            g.log = function(a,b){g("log",[a,b])};
            g.consent=function(a){g("consent",!arguments.length||a)};
            g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
            g.clearUserCookie=function(){};
            g.setVars=function(n, p){g('setVars',[n,p]);};
            g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
            if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
            g._v="1.3.0";
        })(window,document,window['_fs_namespace'],'script','user');
      `}
      </script>
    </div>
  );
};

export default PasswordProtect;
