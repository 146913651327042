module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-v2-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@mkitio/gatsby-theme-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"frontendfront","pagePaths":["/portfolio/","/work/cuker/","/work/gomacro/","/work/ripcurl/","/work/vans/","/work/sunbelt-rentals/","/about/","/notes/","/notes/welcome/","/notes/time-machine/","/notes/win98/","/react/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
